import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'ui/Tab';
import media from 'theme/media';
import { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ILandingLocale, GuideLocaleType } from 'pages/Landing/types';
import { TabWrapper } from './style';

const tabs: ILandingLocale<GuideLocaleType>[] = [
  { id: 0, value: 'nfts' },
  { id: 1, value: 'tron' },
  { id: 2, value: 'exclusive_options' },
  { id: 3, value: 'liquidation' },
];

type TabListProps = {
  onTabChange: (i: number) => void;
  currentTabIndex: number;
};

const TabList: React.FC<TabListProps> = ({ currentTabIndex, onTabChange }) => {
  const { t } = useTranslation('landing');
  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  const handleChangeSlide = (i: number) => {
    swiper?.slideTo(i);
    onTabChange(i);
  };

  return (
    <TabWrapper>
      <Swiper
        onSwiper={setSwiper}
        slidesPerView="auto"
        spaceBetween={16}
        breakpoints={{ [media.tablet]: { spaceBetween: 24 } }}
      >
        {tabs.map(({ value, id }, i) => (
          <SwiperSlide key={id} onClick={() => handleChangeSlide(i)}>
            <Tab active={currentTabIndex === i}>{t(`how_it_works_block.guide_info.${value}.category`)}</Tab>
          </SwiperSlide>
        ))}
      </Swiper>
    </TabWrapper>
  );
};

export default TabList;
