import { ApiEndpointsVault, instanceVault } from 'src/services/Vault/client';

class VaultService {
  static async getVaultProfileData() {
    const response = await instanceVault.get(ApiEndpointsVault.profileData);

    return response;
  }

  static async getVaultWallets() {
    const response = await instanceVault.get(ApiEndpointsVault.getWallets);

    return response;
  }

  static async getVaultTransactionHistory() {
    const response = await instanceVault.get(ApiEndpointsVault.getHistory);

    return response;
  }
}

export default VaultService;
