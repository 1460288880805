import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import {
  OpenedItemtype,
  InventoryPageItem,
  StateOpened,
  MarketplaceMock,
  URLPiece,
  SingleChildren,
} from 'src/types/builder';
import { getSimpleErrorMessage } from 'utils/error';
import { getBuilderConfig, getEquipmentArrayBuilder } from 'utils/getBuilderConfig';
import { getInitialStateBag } from 'store/slices/bag/actionAsync';
import { NewItem } from 'pages/Builder/Build/contants';

export const createNew = createAsyncThunk<OpenedItemtype[], number[], ThunkApiConfig>(
  'builder/create-new',
  async (idArray, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.BuilderService.createItem(idArray);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response;
  },
);

type getItemProps = {
  id: number;
  setConfigFunc: (config1: string[]) => void;
  navigationInv: () => void;
};

export const getItem = createAsyncThunk<StateOpened, getItemProps, ThunkApiConfig>(
  'builder/get-current',
  async ({ id, setConfigFunc, navigationInv }, { extra: services, rejectWithValue, getState }) => {
    const { allItems } = getState().builder;

    const item = allItems.find((el: InventoryPageItem) => el.id === id || el.item === id);

    if (!item?.id) navigationInv();

    if (item?.id) {
      const response: any = await services.httpService.BuilderService.getItem(item.id, item?.url_piece);

      if (response?.isError) {
        const message = getSimpleErrorMessage(response.data);

        return rejectWithValue({ message, isError: true });
      }

      const insideArray = getEquipmentArrayBuilder(response.data);

      const config = getBuilderConfig(response.data, insideArray, allItems);

      setConfigFunc(config);

      return { ...response.data, config };
    }

    return {
      can_be_purchased: false,
      contract_purchase_id: 0,
      id: 0,
      image_link: '',
      max_components: 0,
      product_name: '',
      price: 0,
      created_at: '',
      used: false,
      name: '',
      is_purchased: false,
      is_purchasing: false,
      is_cart: false,
      synced: false,
      components: 0,
      power_rate: 0,
      energy_consumption: 0,
      product: 0,
      config: [''],
      url_piece: 'shelf',
    };
  },
);

type ChildObj = {
  [key in Exclude<URLPiece, 'gpu'>]: URLPiece;
};

const ChildrenObj: ChildObj = {
  rig: 'gpu',
  shelf: 'rig',
  container: 'shelf',
};

export const getAllItems = createAsyncThunk<InventoryPageItem[], void, ThunkApiConfig>(
  'builder/get-all',
  async (_, { extra: services, rejectWithValue }) => {
    const nftType = 'rig,shelf,container';
    const response: any = await services.httpService.BuilderService.getAllItems(nftType);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data.results;
  },
);

export const updateItem = createAsyncThunk<
  OpenedItemtype,
  {
    parent: StateOpened | NewItem;
    children: number[];
  },
  ThunkApiConfig
>('builder/update-current', async ({ parent, children }, { extra: services, rejectWithValue, dispatch, getState }) => {
  const mapped = Object.fromEntries(
    children
      .map((el: number, idx: number) => [`${ChildrenObj[parent.url_piece]}${idx + 1}`, el ?? null])
      .filter((el) => !!el[1]),
  );

  const response: any = await services.httpService.BuilderService.updateItem(
    parent.id,
    parent.url_piece,
    mapped,
    parent.is_cart,
  );

  if (response?.isError) {
    const message = getSimpleErrorMessage(response.data);

    return rejectWithValue({ message, isError: true });
  }
  dispatch(getAllItems());
  dispatch(getInitialStateBag());

  return response;
});

export const getMarketEqp = createAsyncThunk<MarketplaceMock[], URLPiece, ThunkApiConfig>(
  'builder/get-market-eqp',
  async (parentType, { extra: services, rejectWithValue, getState }) => {
    const response: any = await services.httpService.BuilderService.getItemEquipment(parentType);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response;
  },
);

export const getInventoryEqp = createAsyncThunk<SingleChildren[], Exclude<URLPiece, 'gpu'>, ThunkApiConfig>(
  'builder/get-inventory-eqp',
  async (parentType, { extra: services, rejectWithValue, getState }) => {
    const type = ChildrenObj[parentType];

    const response: any = await services.httpService.BuilderService.getAllItems(type);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data.results;
  },
);
