import colors from './colors';
import font from './font';

const dark = {
  screenStoriesBackground: colors.black,
  app: {
    backgroundPrimary: colors.black,
    backgroundSecondary: colors.ultraBlack,
    scrollbarThumb: colors.grey,
  },
  buttons: {
    primary: {
      default: {
        bgColor: colors.yellow,
        textColor: colors.black,
        borderColor: colors.transparent,
      },
      hover: {
        bgColor: colors.yellowBrighter,
        textColor: colors.black,
        borderColor: colors.transparent,
      },
      disabled: {
        bgColor: colors.yellowSubdued,
        textColor: colors.black,
        borderColor: colors.transparent,
      },
    },
    secondary: {
      default: {
        bgColor: colors.onBlack,
        textColor: colors.white,
        borderColor: colors.buttonSecondaryStroke,
      },
      hover: {
        bgColor: colors.onBlack,
        textColor: colors.white,
        borderColor: colors.yellow,
      },
      disabled: {
        bgColor: colors.onBlack,
        textColor: colors.buttonSecondaryStroke,
        borderColor: colors.inputDisabledStroke,
      },
    },
    tertiary: {
      default: {
        bgColor: colors.transparent,
        textColor: colors.white,
        borderColor: colors.transparent,
      },
      hover: {
        bgColor: colors.transparent,
        textColor: colors.yellow,
        borderColor: colors.transparent,
      },
      disabled: {
        bgColor: colors.transparent,
        textColor: colors.buttonSecondaryStroke,
        borderColor: colors.transparent,
      },
    },
  },
  switch: {
    primary: {
      bgActive: colors.yellow,
      bgInactive: colors.inputDefaultFill,
      dotColor: colors.white,
    },
  },
  inputs: {
    defaultBorderColor: colors.buttonSecondaryStroke,
    activeBorderColor: colors.yellowSubdued,
    errorBorderColor: colors.error,
    disabledBorderColor: colors.inputDisabledStroke,
    backgroundColor: colors.inputDefaultFill,
    backgroundInputNumberColor: colors.black,
    disabledBackgroundColor: colors.inputDisabledFill,
    placeholderColor: colors.grey,
    textColor: colors.white,
    textColorSecondary: colors.yellow,
    hoverTextColor: colors.yellow,
    errorTextColor: colors.errorSubdued,
    disabledTextColor: colors.buttonSecondaryStroke,
    trackColor: colors.onBlack,
    progressColor: colors.yellow,
    thumbColor: colors.white,
    radioBorder: colors.grey,
    radioBg: colors.onBlack,
    radioChecked: colors.white,
  },
  tooltip: {
    textColor: colors.white,
    backgroundColor: colors.onBlack,
    borderColor: colors.buttonSecondaryStroke,
  },
  tab: {
    defaultBorderColor: colors.buttonSecondaryStroke,
    activeBorderColor: colors.yellow,
    bgColor: colors.black,
    activeBgColor: colors.onBlack,
    textColor: colors.white,
    activeTextColor: colors.yellow,
  },
  fontsColor: {
    primary: colors.white,
    secondary: colors.grey,
    tertiary: colors.yellow,
    success: colors.success,
    error: colors.error,
  },
  select: {
    dropdownBackground: colors.onBlack,
    hoverBackground: colors.black,
    buttonBackground: colors.onBlack,
    hoverTextColor: colors.yellow,
  },
  navBar: {
    iconBgInactive: colors.onBlack,
    iconBgActive: colors.yellow,
    iconColorInactive: colors.white,
    iconColorActive: colors.black,
    textColorInactive: colors.white,
    textColorActive: colors.yellow,
    groupBg: colors.inputDefaultFill,
  },
  circleProgress: {
    bgColor: colors.onBlack,
    primaryColor: colors.white,
    fullColor: colors.successSubdued,
    warningColor: colors.warningSubdued,
    lowColor: colors.orange,
    dangerColor: colors.errorSubdued,
  },
  rowCoin: {
    bgColor: colors.transparent,
    textColor: colors.white,
    borderColor: colors.buttonSecondaryStroke,
    hoverBorderColor: colors.yellow,
  },
  codeInput: {
    bgColor: colors.inputDefaultFill,
    borderColor: colors.buttonSecondaryStroke,
    activeBorderColor: colors.yellow,
    fontsColor: colors.white,
    incorrectBorderColor: colors.error,
  },
  choiceGroup: {
    bgColor: colors.black,
    bgColorElement: colors.transparent,
    bgSelectedColorElement: colors.onBlack,
    borderColor: colors.buttonSecondaryStroke,
    disabledTextColor: colors.buttonSecondaryStroke,
    colorText: colors.white,
    colorTextHover: colors.yellow,
  },
  bag: {
    bgColor: colors.black,
    bgLayout: colors.blackTransparent,
    bgTransparent: colors.transparent,
    textHoverColor: colors.yellow,
    textColor: colors.white,
  },
  progressLine: {
    bgColor: colors.buttonSecondaryStroke,
    bgActiveColor: colors.yellow,
  },
  card: {
    bgColor: colors.transparent,
    borderColor: colors.whiteTransparent,
    priceBg: colors.onBlack,
  },
  accordion: {
    borderColor: colors.whiteTransparent,
    arrowColor: colors.white,
    borderActiveColor: colors.yellow,
  },
  toast: {
    bg: colors.black,
    error: colors.errorSubdued,
    warning: colors.yellow,
    success: colors.green,
    energy: colors.green,
  },
  KYC: colors.blue,
  popup: {
    dialog: {
      dismiss: colors.onBlack,
      dismissBorder: colors.buttonSecondaryStroke,
      dismissText: colors.white,
    },
  },
  fonts: font,
} as const;

export default dark;
