import React from 'react';
import { ControllerBaseLayout } from 'router/ControllerBaseLayout';
import { All, CurrentAsset } from 'pages';
import { CalculatorPage } from 'pages/MarketPlace/Calculator';
import { ShortContract } from 'pages/MarketPlace/ShortContract';

export const marketRoute = {
  path: 'marketplace',
  children: [
    {
      path: 'market',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack={false}>
          <All />
        </ControllerBaseLayout>
      ),
    },
    // {
    //   path: 'short-contracts',
    //   element: (
    //     <ControllerBaseLayout bg="primary" isHaveButtonBack={false}>
    //       <ShortContract />
    //     </ControllerBaseLayout>
    //   ),
    // },
    {
      path: 'market/:id',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack>
          <CurrentAsset />
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'calculator',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack>
          <CalculatorPage />
        </ControllerBaseLayout>
      ),
    },
  ],
};
