import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconsName } from 'ui';
import { SimpleNotification } from 'ui/Toast/Toast.component';

export enum TypeNotify {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  ENERGY = 'energy',
}

const toastOptions = {
  position: 'bottom-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  hideProgressBar: true,
  limit: 2,
} as const;

const useNotification = () => {
  const showToast = useCallback(
    (message: string, type: TypeNotify, icon: (typeof IconsName)[number] | null, options?: Record<string, unknown>) => {
      const component = <SimpleNotification message={message} type={type} icon={icon} />;
      const props = { ...toastOptions, ...options };
      toast(component, props);
    },
    [],
  );

  const successNotification = useCallback(
    (message: string, icon: (typeof IconsName)[number] | null, options?: Record<string, unknown>) => {
      const type = TypeNotify.SUCCESS;
      showToast(message, type, icon, options);
    },
    [showToast],
  );

  const errorNotification = useCallback(
    (message: string, icon: (typeof IconsName)[number] | null = 'info', options?: Record<string, unknown>) => {
      const type = TypeNotify.ERROR;
      showToast(message, type, icon, options);
    },
    [showToast],
  );

  const warningNotification = useCallback(
    (message: string, icon: (typeof IconsName)[number] | null = 'warning', options?: Record<string, unknown>) => {
      const type = TypeNotify.WARNING;
      showToast(message, type, icon, options);
    },
    [showToast],
  );

  return useMemo(
    () => ({
      successNotification,
      errorNotification,
      warningNotification,
    }),
    [errorNotification, successNotification, warningNotification],
  );
};

export default useNotification;
