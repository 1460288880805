import styled from 'styled-components';
import { Icon } from 'ui';
import { ToastContainer } from 'react-toastify';
import { media } from 'theme';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 25rem;
    height: 3.75rem;
    padding: 0;
    bottom: 5em;
    z-index: 2147483002;
  }
  .Toastify__toast {
    padding: 0;
    min-height: 0;
    background: transparent;
  }
  .Toastify__toast-body {
    padding: 0;
  }

  &&&.Toastify__close-button {
    display: none;
  }
`;
export const ToastWrapper = styled.div`
  width: 25rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.toast.bg};

  @media (max-width: ${media.xs}px) {
    width: 20rem;
  }
`;

type ToastIconProps = {
  $type: string;
};
export const ToastIcon = styled(Icon)<ToastIconProps>`
  width: 3rem;
  height: 3.75rem;
  padding: 1rem 0.75rem;
  border-radius: 0.5rem 0 0;
  background: ${({ $type, theme }) => theme.toast[$type]};
`;

export const ToastCloseIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  color: ${({ theme }) => theme.fontsColor.primary};
`;

export const ToastMessage = styled.div`
  width: 17rem;
  margin: 0.75rem 0.75rem 0.75rem 1.5rem;
  color: ${({ theme }) => theme.fontsColor.primary};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
`;
