import React, { lazy } from 'react';
import { ControllerBaseLayout } from 'router/ControllerBaseLayout';
import {
  ErrorBoundary,
  dataCenterRoute,
  // raceRoutesBase,
  marketRoute,
  profileRoutes,
  rewardsRoute,
  supportRoute,
  builderRoute,
} from 'pages';

import Docs from './Docs';

export const BaseLayoutComponent = lazy(() => import('components/BaseLayout'));

export const appRouter = {
  path: '',
  element: <BaseLayoutComponent />,
  errorElement: <ErrorBoundary />,
  children: [
    dataCenterRoute,
    marketRoute,
    profileRoutes,
    supportRoute,
    rewardsRoute,
    // raceRoutesBase,
    builderRoute,
    // newsRoutes,
    {
      path: 'docs',
      children: [],
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack={false}>
          <Docs />
        </ControllerBaseLayout>
      ),
    },
  ],
};
