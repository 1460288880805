import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VaultProfileDataAction, VaultProfileType, VaultUserInfo } from 'store/slices/vault/types';
import { userLogoutRequest } from 'store/slices/user/actionAsync';
import { VaultAllowedTransactionType } from 'widgets/LW/types';
import {
  getKYC,
  getVaultProfileData,
  getVaultTransactionHistory,
  getVaultWallets,
  VaultKYCResponse,
  vaultConnectWalletRequest,
  vaultRegistrationRequest,
} from './actionAsync';

const AllowedTransactions = [
  { model: 'receiveCryptoModel', title: 'Crypto Received' },
  { model: 'payinAdvcashModel', title: 'AdvCash Top-Up' },
  { model: 'payinCardModel', title: 'Card Top-Up' },
  { model: 'payoutCardModel', title: 'Withdraw to Card' },
  { model: 'sendToWalletModel', title: 'Transfer To Wallet' },
  { model: 'sendToPhoneModel', title: 'Transfer by Phone' },
];

type InitState = {
  phone: string;
  isLoading: boolean;
  wallets: [];
  transactionHistory: [];
  vaultProfile: VaultProfileType | null;
  kyc: VaultKYCResponse | null;
  vaultUserInfo: VaultUserInfo | null;
  isConfirmUserEmail: boolean;
};

const initState: InitState = {
  phone: '',
  isLoading: false,
  wallets: [],
  transactionHistory: [],
  vaultProfile: null,
  kyc: null,
  vaultUserInfo: null,
  isConfirmUserEmail: false,
};

const vaultSlice = createSlice({
  name: 'vault',
  initialState: initState,
  reducers: {
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
  },
  extraReducers: (builder) => {
    // TODO: type!
    builder
      .addCase(vaultRegistrationRequest.fulfilled, (state, action: any) => {
        state.phone = action.payload.phone_number;
      })
      .addCase(vaultConnectWalletRequest.fulfilled, (state, action: any) => {
        state.phone = action.payload.phone_number;
      })
      .addCase(getVaultWallets.fulfilled, (state, action: any) => {
        state.wallets = action.payload.wallets;
      })
      .addCase(getVaultTransactionHistory.fulfilled, (state, action: any) => {
        const transactionHistory = action.payload;
        const filteredArray: VaultAllowedTransactionType | [] = [];
        if (transactionHistory.length) {
          transactionHistory.forEach((elem) => {
            const keysArray = Object.keys(elem);
            let transactionModel = null;
            AllowedTransactions.forEach((transaction) => {
              if (keysArray.includes(transaction.model) && elem[transaction.model] !== null) {
                transactionModel = {
                  title: transaction.title,
                  currency: elem[transaction.model].amount?.currency || elem[transaction.model].debitAmount?.currency,
                  value: elem[transaction.model].amount?.value || elem[transaction.model].debitAmount?.value,
                  operationStatus: elem.operationStatus,
                };
              }
            });
            if (transactionModel) {
              filteredArray.push(transactionModel);
            }
          });
        }
        state.transactionHistory = filteredArray;
      })
      .addCase(getVaultProfileData.fulfilled, (state, action: PayloadAction<VaultProfileDataAction>) => {
        state.vaultProfile = action.payload.vaultProfile;
        state.vaultUserInfo = action.payload.vaultUserInfo;
        state.isConfirmUserEmail = !!action.payload.vaultUserInfo?.profile.is_email_verified;
      })
      .addCase(userLogoutRequest.fulfilled, () => initState)
      .addCase(getKYC.fulfilled, (state, action: PayloadAction<VaultKYCResponse>) => {
        state.kyc = action.payload;
      })
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('vault') && type.endsWith('/pending');
        },
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('vault') && type.endsWith('/rejected');
        },
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('vault') && type.endsWith('/fulfilled');
        },
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const { setPhone } = vaultSlice.actions;

export default vaultSlice.reducer;
