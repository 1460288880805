import React, { FC } from 'react';
import { ToastCloseIcon, ToastIcon, ToastMessage, ToastWrapper } from 'ui/Toast/Toast.styled';
import { IconsName } from 'ui/Icon';

export const TOAST_ID = 'toastify';

type SimpleNotificationProps = {
  message: string;
  type: string;
  icon: (typeof IconsName)[number] | null;
};
export const SimpleNotification: FC<SimpleNotificationProps> = ({ message, type, icon }) => (
  <ToastWrapper id={TOAST_ID}>
    <ToastIcon name={icon} $type={type} />
    <ToastMessage>{message}</ToastMessage>
    <ToastCloseIcon name="close" />
  </ToastWrapper>
);
