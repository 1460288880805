import styled, { css } from 'styled-components';
import { H3 } from 'ui/Text';
import colors from 'theme/colors';
import media from 'theme/media';
import { CardLabel, FullImg } from './components/Guides/style';

export const TabList = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: ${media.tablet}px) {
    overflow-x: scroll;
  }
`;

type CardStyleProps = {
  selected?: boolean;
};

export const RotatedCard = styled.div<CardStyleProps>`
  border: 2px solid ${({ selected }) => (selected ? colors.yellowSubdued : '#ffffff20')};
  z-index: ${({ selected }) => (selected ? '2' : '0')};
  display: flex;
  width: 284px;
  height: 327px;
  padding: 16px 24px 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  cursor: pointer;
  position: absolute;
  overflow: hidden;

  ${FullImg} {
    transform: scale(${({ selected }) => (selected ? 1.3 : 1.2)});
  }

  ${CardLabel} {
    ${({ selected }) =>
      selected &&
      css`
        opacity: 1;
      `}
  }

  &:hover {
    ${CardLabel} {
      opacity: 1;
    }
  }

  &:first-child {
    transform: translate(30px, 70px) rotate(-5deg);
  }

  &:nth-child(2) {
    transform: translate(70px, 0px) rotate(5deg);
  }

  &:last-child {
    transform: translate(0px, -70px) rotate(-2deg);
  }

  @media (max-width: ${media.tablet}px) {
    &:first-child {
      transform: translate(-30px, 70px) rotate(-5deg);
    }

    &:nth-child(2) {
      transform: translate(-70px, 0px) rotate(5deg);
    }

    &:last-child {
      transform: translate(0px, -70px) rotate(-2deg);
    }
  }

  @media (max-width: ${media.mobile}px) {
    &:first-child {
      transform: translate(0px, 70px) rotate(-5deg);
    }

    &:nth-child(2) {
      transform: translate(0px, 0px) rotate(5deg);
    }

    &:last-child {
      transform: translate(0px, -70px) rotate(-2deg);
    }

    width: 244px;
    height: 297px;
  }
`;

export const RotatedCardWrap = styled.div`
  position: relative;
  width: 382px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;

  @media (max-width: ${media.mobile}px) {
    justify-self: center;
    width: unset;
  }
`;

export const Wrapper = styled.div`
  padding: 0 194px;

  @media (max-width: ${media.laptop}px) {
    padding: 0 60px;
  }

  @media (max-width: ${media.tablet}px) {
    padding: 0 40px;
  }

  @media (max-width: ${media.tablet}px) {
    padding: 0 16px;
  }
`;

type WorkGuideSectionProps = {
  $background: string;
};

export const WorkGuideSection = styled.section<WorkGuideSectionProps>`
  background-image: ${({ $background }) => `url(${$background})`};
  background-color: ${colors.black};
  width: 100%;
  padding: 12.125rem 6.25rem;
  max-width: 100rem;
  margin: 0 auto;

  @media (max-width: ${media.laptop}px) {
    padding: 5rem 3.75rem;
  }

  @media (max-width: ${media.tablet}px) {
    padding: 5rem 2.5rem;
  }

  @media (max-width: ${media.mobile}px) {
    padding: 5rem 1rem;
  }
`;

export const OffsetTitle = styled(H3)`
  margin: 24px 0 40px;
`;
