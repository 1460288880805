import React, { ChangeEventHandler, FC, memo, ReactNode, useEffect, useId, useMemo, useRef, useState } from 'react';
import { Flex, IconsName } from 'ui';
import { getSeparatedNumber } from 'pages/Rewards/utils';
import useMediaQuery from 'hooks/useMediaQuery';
import {
  InputWrapper,
  InputLabel,
  InputField,
  HideToggle,
  InputNumberWrapper,
  InputNumberText,
  IconInputNumber,
  ToolTipWrapper,
  ToolTipIcon,
  InputRangeWrapper,
  RangeValue,
  CustomInputRange,
  TextRadio,
  Radio,
  RadioContainer,
  CustomRadio,
  CustomRadioContainer,
  InputRangeDiscreteStyled,
  GridInput,
  StyledP,
  DiscreteFlex,
} from './Input.styled';

type InputProps = React.ComponentProps<'input'> & {
  placeholder: string;
  error?: string;
  disabled?: boolean;
  isLocked?: boolean;
  className?: string;
  icon?: (typeof IconsName)[number];
  onCopy?: (toCopy: string) => void;
  isEllipsis?: boolean;
  iconTooltipText?: string;
  inputEndAdornment?: ReactNode;
};

/**
 * Primary UI component for user interaction
 */

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      error,
      disabled,
      className,
      icon = null,
      iconTooltipText = '',
      isEllipsis = false,
      onCopy,
      inputEndAdornment,
      isLocked = false,
      ...props
    },
    ref,
  ) => (
    <ToolTipWrapper inactive={!error} content={error} icon="warning">
      <InputWrapper error={error} disabled={disabled} className={className} isLocked={isLocked}>
        <InputField
          $isEllipsis={isEllipsis}
          ref={ref}
          error={error}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
          {...props}
        />
        <InputLabel disabled={disabled}>{placeholder}</InputLabel>
        {iconTooltipText ? (
          <ToolTipIcon className={className} inline content={iconTooltipText} icon={null}>
            <HideToggle onClick={onCopy} name={icon} />
          </ToolTipIcon>
        ) : (
          <HideToggle onClick={onCopy} name={icon} />
        )}
        {inputEndAdornment}
      </InputWrapper>
    </ToolTipWrapper>
  ),
);

export const InputPassword = React.forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder, error, disabled, className, isEllipsis = false, ...props }: InputProps, ref) => {
    const [hidden, setHidden] = useState(true);

    const toggleHidden = () => setHidden((val) => !val);

    return (
      <ToolTipWrapper inactive={!error} content={error} icon="warning">
        <InputWrapper error={error} disabled={disabled} className={className}>
          <InputField
            type={hidden ? 'password' : 'text'}
            error={error}
            ref={ref}
            $isEllipsis={isEllipsis}
            placeholder={placeholder}
            disabled={disabled}
            {...props}
          />
          <InputLabel disabled={disabled}>{placeholder}</InputLabel>
          <HideToggle name={hidden ? 'hide' : 'show'} onClick={toggleHidden} data-test-id="showPassBtn" />
        </InputWrapper>
      </ToolTipWrapper>
    );
  },
);

export interface InputNumberProps {
  className?: string;
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  text?: string;
  value?: number;
  onChange?: (num: number) => void;
  increment?: () => void;
  decrement?: () => void;
}

export const InputNumber: React.FC<InputNumberProps> = ({
  defaultValue = 1,
  step = 1,
  max = 0,
  min = 0,
  text = '',
  ...props
}) => {
  const [amount, setAmount] = useState<number>(defaultValue);

  useEffect(() => {
    if (props.value) setAmount(props.value);
  }, [props.value]);

  const increaseAmount = () => {
    if (amount >= (max || 0)) return;

    if (amount + step >= max) {
      setAmount(max);
      if (props.onChange) props.onChange(max);
      if (props.increment) props.increment();
    } else {
      setAmount((val) => val + step);
      if (props.onChange) props.onChange(amount + step);
      if (props.increment) props.increment();
    }
  };
  const decreaseAmount = () => {
    if (amount <= (min || 0)) return;

    if (amount - step <= min) {
      setAmount(min);
      if (props.onChange) props.onChange(min);
      if (props.decrement) props.decrement();
    } else {
      setAmount((val) => {
        if (val - step <= min) return min;
        return val - step;
      });
      if (props.onChange) props.onChange(amount - step);
      if (props.decrement) props.decrement();
    }
  };

  return (
    <InputNumberWrapper className={props.className}>
      <IconInputNumber name="minus" onClick={decreaseAmount} />
      <InputNumberText>{`${amount}${text}`}</InputNumberText>
      <IconInputNumber name="plus" onClick={increaseAmount} />
    </InputNumberWrapper>
  );
};

type InputRangeProps = {
  value: number;
  min?: number;
  max?: number;
  isDisable?: boolean;
};

export const InputRange: FC<InputRangeProps> = memo(({ value = 0, min = 0, max = 0 }) => {
  const width = useMemo(() => (value * 100) / (max - min), [value, min, max]);

  const preparedValues = () => {
    const preparedMin = min && getSeparatedNumber(min);
    const preparedMax = max && getSeparatedNumber(max);

    return {
      preparedMin,
      preparedMax,
    };
  };

  const { preparedMin, preparedMax } = preparedValues();

  return (
    <InputRangeWrapper>
      <CustomInputRange type="range" min={min} max={max} $width={width} value={value} />
      <Flex $justify="space-between" $align="center">
        <RangeValue fontFamily="secondary" color="secondary" size="sm">
          {preparedMin}
        </RangeValue>
        <RangeValue fontFamily="secondary" color="secondary" size="sm">
          {preparedMax}
        </RangeValue>
      </Flex>
    </InputRangeWrapper>
  );
});

type InputRatioProps = {
  text: string;
  checkedState: string;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: (name: string) => void;
};

export const InputRadio: FC<InputRatioProps> = ({ text, checkedState, name, onChange, onClick }) => {
  const id = useId();

  const clickHandler = () => {
    if (onClick) onClick(name);
  };

  const isChecked = name === checkedState;

  return (
    <RadioContainer $align="center" onClick={clickHandler}>
      <Radio type="radio" id={id} checked={isChecked} name={name} onChange={onChange} />
      <CustomRadioContainer $align="center" $justify="center">
        <CustomRadio $checked={isChecked} />
      </CustomRadioContainer>
      <TextRadio htmlFor={id}>{text}</TextRadio>
    </RadioContainer>
  );
};

interface InputRangeDiscreteProps {
  defaultValue: number;
  onChange: (value: number) => void;
  discrete: number[];
}

export const InputRangeDiscrete: FC<InputRangeDiscreteProps> = (props) => {
  const { defaultValue, discrete, onChange: rangeCallback } = props;

  const idDefaultValue = discrete.findIndex((el) => el === defaultValue);

  const refText = useRef<Array<HTMLParagraphElement | null>>([]);
  const [widthFirstAndLastText, setWidthFirstAndLastText] = useState(0);

  const [thumbValue, setThumbValue] = useState(0);
  const [isGrabbing, setIsGrabbing] = useState(false);

  const { isMobile } = useMediaQuery();

  const amountElements = discrete.length;
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setThumbValue(Number(e.target.value));
  };

  useEffect(() => {
    const widthFirst = refText.current[0]?.clientWidth || 0;
    const widthLast = refText.current[discrete.length - 1]?.clientWidth || 0;
    setWidthFirstAndLastText(widthFirst + widthLast);
  }, [refText, isMobile]);

  useEffect(() => {
    if (!isGrabbing) {
      const targetRangeValue = Math.round(thumbValue);
      setThumbValue(targetRangeValue);
      rangeCallback(discrete[targetRangeValue]);
    }
  }, [isGrabbing]);

  const getPercent = (index: number) => Math.round((index * 100) / (amountElements - 1));

  const isEven = (n: number) => n % 2 === 0;
  const nearFloorEvenNumber = Math.floor(amountElements / 2) * 2;

  const getAmendOnSLiderThumb = (index: number) => {
    if (isEven(amountElements)) {
      const average = (amountElements - 1) / 2;
      if (index < average) return (index * 100) / amountElements;
      return 100 - ((amountElements - index - 1) * 100) / amountElements;
    }

    return (index * 100) / nearFloorEvenNumber; // odd
  };

  const DEFAULT_VALUE = idDefaultValue > 0 ? idDefaultValue : 0;

  return (
    <Flex $justify="center" $align="center">
      <DiscreteFlex
        $direction="column"
        $align="center"
        $justify="center"
        $width={Math.floor(widthFirstAndLastText / 2)}
      >
        <GridInput $columns={discrete.length}>
          {discrete.map((value, index) => (
            <StyledP
              size="sm"
              fontFamily="secondary"
              bold="lg"
              key={value}
              $left={getPercent(index)}
              $amendmentOnThumb={getAmendOnSLiderThumb(index)}
              ref={(ref) => refText.current.push(ref)}
            >
              {value}
            </StyledP>
          ))}
        </GridInput>
        <InputRangeDiscreteStyled
          step={0.01}
          value={thumbValue}
          defaultValue={DEFAULT_VALUE}
          onChange={onChangeHandler}
          min={0}
          max={discrete.length - 1}
          onMouseDown={() => setIsGrabbing(true)}
          onMouseUp={() => setIsGrabbing(false)}
          onTouchStart={() => setIsGrabbing(true)}
          onTouchEnd={() => setIsGrabbing(false)}
          $isGrabbing={isGrabbing}
        />
      </DiscreteFlex>
    </Flex>
  );
};
