export interface GetProductsCollection {
  collection?: number;
  page?: number;
  collection__isnull?: boolean;
}

interface ProductContracts {
  id: number;
  address: string;
  price: number;
  image: string | null;
}

export interface ProductCollection {
  id: number;
  name: string;
}

export interface ProductCard {
  id: number;
  collection: ProductCollection;
  contract_purchase_id: number;
  contracts: ProductContracts[];
  is_combo_pack: boolean;
  price_before_discount: number;
  created_at: string;
  title: string;
  desc: string;
  price: number;
  income?: number;
  img: string;
  month_durability: number;
  hide: boolean;
  pre_title: string;
}

type LandingBtcConstantType = {
  value: string;
  name: string;
};

export interface ILandingProduct extends ProductCard {
  order: number;
  constants: LandingBtcConstantType[];
  collectionName: string;
  btcReward: string;
  roiPercent: string;
}

export const LandingProductsBtcReward: Record<number, string> = {
  19: '0,00013',
  20: '0,0004',
  21: '0,00041',
  22: '0,0013',
  23: '0,0014',
  24: '0,0042',
};

export const LandingProductsRoiPercent: Record<number, string> = {
  19: '105',
  20: '115,75',
  21: '105,5',
  22: '117,25',
  23: '106',
  24: '118,75',
};

export const ProductsMonthDurability: Record<number, number> = {
  19: 1,
  20: 3,
  21: 1,
  22: 3,
  23: 1,
  24: 3,
};

export type GetLandingProductsType = {
  count: number;
  results: ILandingProduct[];
};

export interface Product {
  id: number;
  products: ProductCard[];
  created_at: string;
  bg: string;
  bg_mobile: string;
  bg_subject: string;
  bg_desc: string;
  subject: string;
  desc: string;
  collection: number;
}

export interface GetProductsCollectionResponse {
  count: number;
  results: Product[];
}

export interface GetProductPageRequest {
  id: number;
}

interface ProductAboutBlock {
  id: number;
  name: string;
  title: string;
  created_at?: string;
  image_url?: string;
  desc?: string;
}

export interface ProductInfoBlock {
  id: number;
  name: string;
  title: string;
  created_at?: string;
  image_url?: string;
  desc?: string;
}

export interface ProductSpecification {
  id: number;
  items: ProductInfoBlock[];
  name: string;
  image: string;
  created_at?: string;
}

export interface GetProductPageResponse {
  id: number;
  product: ProductCard;
  about_block: ProductAboutBlock;
  features: ProductInfoBlock[];
  specifications: ProductSpecification[];
  related_products: ProductCard[];
  prediction_blocks: PredictionBlock[];
  title: string;
  pre_title: string;
  created_at?: string;
  bg?: string;
  video_link: string;
}

export interface PredictionBlock {
  id: number;
  created_at?: string;
  title: string;
  value: number;
  order: number;
}

export interface ICollectionChapter {
  id: number;
  bg: string;
  bg_desc: string;
  bg_mobile: string;
  bg_subject: string;
  collection: string | null;
  subject: string;
  desc: string;
  created_at: string;
  products: ProductCard[];
}

export enum SlugScProductPages {
  SC_PRODUCT_PAGES = 'sc-asics-product-page',
}

export enum SCConstantsEnum {
  TOTAL_INCOME = 'total_income',
  ROI = 'roi',
  TOTAL_MINING_REWARD = 'total_mining_reward',
  TYPE = 'type',
}

export type SCConstants = {
  value: string;
  name: SCConstantsEnum;
};

export type SCProductType = {
  id: number;
  collection: ProductCollection;
  contracts: ProductContracts[];
  constants?: SCConstants[];
  is_combo_pack: boolean;
  price_before_discount: number;
  created_at: string;
  title: string;
  desc: string;
  price: number;
  image: string;
  month_durability: number;
  hide: boolean;
  contract_purchase_id: number;
  order: number;
};

export type ProductSCSectionType = {
  id: number;
  updated_at: string;
  description: string;
  title: string;
  order: number;
  bg: string | null;
  bg_mobile: string | null;
  page: string;
};
export interface GetProductShortContractPageResponse {
  slug: SlugScProductPages;
  products: SCProductType[];
  sections: ProductSCSectionType[];
  created_at: string;
}
