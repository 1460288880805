import { styled } from 'styled-components';
import media from 'theme/media';
import { Button, P } from 'ui';

export const WorkArticle = styled.article`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 50px;
  gap: 40px;

  @media (max-width: ${media.mobile}px) {
    grid-template-columns: 1fr;
  }
`;

export const TextWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${media.mobile}px) {
    order: 2;
  }
`;

export const GuideButton = styled(Button.Link)`
  width: 100%;
  max-width: 170px;
  @media (max-width: ${media.mobile}px) {
    max-width: 100%;
  }
`;

export const FullImg = styled.img`
  transition: 0.3s;
  width: 100%;
`;

export const CardLabel = styled(P)`
  position: absolute;
  bottom: 20px;
  transition: 0.3s;
  opacity: 0;
  font-weight: 700;
`;
