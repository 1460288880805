import * as Yup from 'yup';
import { emailRegExp, referralLinkRegex, usernameRegExp } from 'appConstants/validation';

export const Validation = {
  isEmail: Yup.string().matches(emailRegExp, { excludeEmptyString: true, message: 'Invalid email', name: 'email' }),
  isPassword: Yup.string()
    .matches(/[A-Z]/, 'The password must contain at least one capital letter')
    .matches(/[a-z]/, 'The password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'The password must contain at least one digit')
    .min(8, 'Password must not be shorter than 8 characters')
    .max(30, 'The password must not be longer than 30 characters')
    .matches(/^[a-zA-Z0-9!?,."\-+=]+$/, 'Invalid characters in password')
    .required('Password is required'),
  isConfirmPassword: (key: string) =>
    Yup.string()
      .oneOf([Yup.ref(key)], 'Password mismatch')
      .required('Confirm Password is required'),
  isString: Yup.string(),
  isDate: Yup.date(),
  isPhone: Yup.string()
    .required('This field is required')
    .min(10, 'Phone number should be 10 - 18 digits long')
    .max(18, 'Phone number should be 10 - 18 digits long'),
  isBoolean: Yup.boolean(),
  isPositiveNumber: Yup.number().positive(),
  isCodeEmail: Yup.string().matches(/^[0-9]{6}$/, 'Code must be 6 digits'),
  isCodePhone: Yup.string().matches(/^[0-9]{4}$/, 'Code must be 4 digits'),
  isUsername: Yup.string()
    .min(2, 'Username must be at least 2 characters')
    .lowercase()
    .matches(usernameRegExp, { message: 'Invalid username', excludeEmptyString: true }),
  isReferralLinkOrUsername: Yup.string().test('referrer', 'Invalid link or username', (value) => {
    if (value) {
      return usernameRegExp.test(value) || referralLinkRegex.test(value);
    }

    return false;
  }),
};
