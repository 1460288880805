import React from 'react';
import { ControllerBaseLayout } from 'router/ControllerBaseLayout';
import { Wallet, Account } from 'pages';
import { PrivateRouteProvider } from 'components/AuthComponents/AuthProvider';
import AchievementPageController from 'src/HOC/AchievementPageController';
import { SendCoin } from 'pages/Profile/SendCoin';
import { Withdraw } from 'pages/Profile/Withdraw';
import { LinkWallet } from 'pages/Profile/LinkWallet';
import { MoreTransaction } from 'pages/Profile/MoreTransaction';
import { ChangePassword } from 'pages/Profile/ChangePassword';
import { AuthLayoutController } from 'router/AuthLayoutController';
import { Gems } from 'pages/Profile/Gems';
import { MyNfts } from 'pages/Profile/MyNfts';
import { Notifications } from 'pages/Profile/Notifications';
import { LinkTelegram } from 'pages/Profile/LinkTelegram';
import { LinkPhone } from 'pages/Profile/LinkPhone';
import { Details } from 'pages/Profile/Details';

export const profileRoutes = {
  path: 'profile',
  children: [
    {
      path: 'wallet',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack>
          <PrivateRouteProvider>
            <Wallet />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'account',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack>
          <PrivateRouteProvider>
            <Account />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'gems',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack={false}>
          <PrivateRouteProvider>
            <Gems />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'achievements/',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack={false}>
          <PrivateRouteProvider>
            <AchievementPageController isMainAchievementPage />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'achievements/:idAchievement',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack>
          <PrivateRouteProvider>
            <AchievementPageController />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'myNfts/',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack={false}>
          <PrivateRouteProvider>
            <MyNfts />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'notifications/',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack={false}>
          <PrivateRouteProvider>
            <Notifications />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
  ],
};

export const profileRoutesAuthWrapper = {
  path: 'profile',
  children: [
    {
      path: 'change-password',
      element: (
        <AuthLayoutController hasBackButton>
          <PrivateRouteProvider>
            <ChangePassword />
          </PrivateRouteProvider>
        </AuthLayoutController>
      ),
    },
    {
      path: 'wallet/send/:idCoin',
      element: (
        <AuthLayoutController hasBackButton>
          <PrivateRouteProvider>
            <SendCoin />
          </PrivateRouteProvider>
        </AuthLayoutController>
      ),
    },
    {
      path: 'wallet/withdraw/:idCoin',
      element: (
        <AuthLayoutController hasBackButton>
          <PrivateRouteProvider>
            <Withdraw />
          </PrivateRouteProvider>
        </AuthLayoutController>
      ),
    },
    {
      path: 'wallet/link-wallet/:idCoin',
      element: (
        <AuthLayoutController hasBackButton>
          <PrivateRouteProvider>
            <LinkWallet />
          </PrivateRouteProvider>
        </AuthLayoutController>
      ),
    },
    {
      path: 'transaction/:transactionsId',
      element: (
        <AuthLayoutController hasBackButton>
          <PrivateRouteProvider>
            <MoreTransaction />
          </PrivateRouteProvider>
        </AuthLayoutController>
      ),
    },
    {
      path: 'details',
      element: (
        <AuthLayoutController hasBackButton>
          <PrivateRouteProvider>
            <Details />
          </PrivateRouteProvider>
        </AuthLayoutController>
      ),
    },
    {
      path: 'telegram',
      element: (
        <AuthLayoutController hasBackButton>
          <PrivateRouteProvider>
            <LinkTelegram />
          </PrivateRouteProvider>
        </AuthLayoutController>
      ),
    },
    {
      path: 'phone',
      element: (
        <AuthLayoutController hasBackButton>
          <PrivateRouteProvider>
            <LinkPhone />
          </PrivateRouteProvider>
        </AuthLayoutController>
      ),
    },
  ],
};
