import React, { useState, useEffect } from 'react';
import useMediaQuery from 'hooks/useMediaQuery';

import { nanoid } from '@reduxjs/toolkit';

import type { Swiper as SwiperCoreType } from 'swiper/types';

import { Icon } from 'ui';
import { TabsWrapperParent, TabsSwiperWrapper, TabsSwiperSlide, TabsNavigationButton } from './TabsGroup.styled';

enum SwiperDirections {
  LEFT,
  RIGHT,
}

const prepareSlidesContent = (data: React.ReactNode[]) =>
  data.map((element) => {
    const slideID = nanoid();
    return <TabsSwiperSlide key={slideID}>{element}</TabsSwiperSlide>;
  });

type TabsWrapperPropsType = {
  children: React.ReactNode[];
  isPrimaryBackground?: boolean;
  tag?: unknown;
};

const TabsGroup = React.memo<TabsWrapperPropsType>(({ children, isPrimaryBackground, tag }) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCoreType | null>(null);

  const [isLeftNavigationButtonAppears, setIsLeftNavigationButtonAppears] = useState(false);
  const [isRightNavigationButtonAppears, setIsRightNavigationButtonAppears] = useState(false);

  const [isSliderEndReached, setIsSlideEndReached] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const { isMobile } = useMediaQuery();

  const handleSlideNavigation = (direction: SwiperDirections) => {
    if (!swiperInstance) return;

    if (direction === SwiperDirections.RIGHT) {
      swiperInstance.slideNext();
      setActiveSlideIndex(activeSlideIndex + 1);
    } else {
      swiperInstance.slidePrev();
      setActiveSlideIndex(activeSlideIndex - 1);
      setIsSlideEndReached(false);
    }
  };

  useEffect(() => {
    setIsRightNavigationButtonAppears(!isSliderEndReached);

    const isSliderStartReached = activeSlideIndex <= 0;
    setIsLeftNavigationButtonAppears(!isSliderStartReached);
  }, [activeSlideIndex, isSliderEndReached]);

  const sliderItems = prepareSlidesContent(children);

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update();
      swiperInstance.slideTo(0);
      setActiveSlideIndex(0);
      setIsSlideEndReached(swiperInstance.isEnd);
    }
  }, [tag]);

  const sliderGapValue = isMobile ? 16 : 24;

  return (
    <TabsWrapperParent>
      <TabsNavigationButton
        $isLeftSide
        $isVisible={isLeftNavigationButtonAppears}
        $isPrimaryBackground={isPrimaryBackground}
        onClick={() => handleSlideNavigation(SwiperDirections.LEFT)}
      >
        <Icon name="arrowLeft" />
      </TabsNavigationButton>
      <TabsSwiperWrapper
        initialSlide={0}
        slidesPerView="auto"
        spaceBetween={sliderGapValue}
        draggable={false}
        allowTouchMove={false}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        onActiveIndexChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
        onReachEnd={() => setIsSlideEndReached(true)}
      >
        {sliderItems}
      </TabsSwiperWrapper>
      <TabsNavigationButton
        $isVisible={isRightNavigationButtonAppears}
        $isPrimaryBackground={isPrimaryBackground}
        onClick={() => handleSlideNavigation(SwiperDirections.RIGHT)}
      >
        <Icon name="arrowRight" />
      </TabsNavigationButton>
    </TabsWrapperParent>
  );
});

export default TabsGroup;
