import { IconName, IconsName } from 'ui';
import { ChapterType } from 'pages/Profile/Wallet/components/Content/TransactionsHistory/constants';

export type HistoryType = TransactionType | TransferType | MiningActivityType;

export interface IBalanceSlice {
  balance: Coin[];
  transactions: TransactionType[];
  transfers: TransferType[];
  miningActivity: MiningActivityType[];
  chapter: ChapterType;
  filterDateRange: string;
  filterDate: string;
  activeCoinId: number;
  codeIsSending: boolean;
  sendingUserId: number | null;
  isValidAddress: boolean | null;
  isUserFindByWallet: boolean | null;
  isLoading: boolean;
  isBalanceLoaded: boolean;
  isTransactionsLoaded: boolean;
  isTransfersLoaded: boolean;
  isLoadingWithdrawCoin: boolean;
  isMiningLoaded: boolean;
}

export type Coin = {
  id: number;
  name: (typeof IconsName)[number];
  image: string;
  min_withdraw: number;
  max_withdraw: number;
  user_wallet: {
    id: number;
    wallet_address: string;
    value: string;
    created_at: string;
  };
  created_at: string;
};

type CryptoToken = {
  id: number;
  image: string;
  name: IconName;
};

export enum TransactionCategory {
  MINED = 'MINED',
  REFERRAL = 'REFERRAL',
}

export type TransactionType = {
  id: number;
  tx_hash: string;
  crypto_token: CryptoToken;
  category: string;
  value: string;
  status: 1 | 2 | 3 | 4;
  created_at: string;
  type: TransactionCategory;
};

type UserInfo = {
  id: number;
  username: string;
  email?: string;
  wallet_address?: string;
};

export type TransferType = {
  user1: UserInfo;
  user2: UserInfo;
  crypto_token: CryptoToken;
  value: number;
  created_at: string;
  category: string;
  status: 1;
  id: number;
};

export type MiningActivityType = {
  crypto_token: CryptoToken;
  created_at__date: string;
  created_at: string;
  sum_value: string;
  value: number;
  category: string;
  status: 1;
  id: number;
};

export type ResultSendCoinType = {
  id: number;
  type: number;
  created_at: string;
  updated_at: string;
  value: number;
  user1: number;
  user2: number;
  crypto_token: {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    network: string;
    min_withdraw: number;
    max_withdraw: number;
    long_name: string;
    coin_gecko_name: string;
    commission: number;
    link: string;
    desc: string;
    image: string;
    coin_stats_display: boolean;
    price: number;
    market_cap: number;
    network_hash_rate: number;
    network_hash_rate_unit: string;
    network_difficulty: number;
    network_difficulty_unit: string;
    network_reward_block: number;
    changes_in_24: number;
    whitepaper: string;
    wallet_instructions: string;
    unit: string;
    delete_dt: null;
    hide: boolean;
    order: number;
  };
};

export type ResultSendCodeToEmailType = {
  id: number;
  created_at: string;
  updated_at: string;
  code_type: number;
};
