import React from 'react';

export const IconsName = [
  'halfCirledArrow',
  'installed',
  'trash',
  'builder',
  'plusBorder',
  'TRX',
  'energy',
  'no_coin',
  'power_grid',
  'coin_stats',
  'instructions',
  'CFX',
  'CLORE',
  'DNX',
  'ERG',
  'ETC',
  'ETHW',
  'FLUX',
  'HCFC',
  'HCFH',
  'HCFW',
  'IRON',
  'KAS',
  'NEOX',
  'NEX',
  'RVN',
  'doubleArrow',
  'arrowRight',
  'arrowLeft',
  'arrowLeftLine',
  'arrowDown',
  'arrowUpLine',
  'arrowDownLine',
  'disabledArrowDownLine',
  'loading',
  'google',
  'tronLink',
  'twitter',
  'show',
  'hide',
  'plus',
  'minus',
  'kas',
  'btc',
  'etc',
  'erg',
  'nex',
  'flux',
  'rvn',
  'dnx',
  'neox',
  'clore',
  'cfx',
  'iron',
  'xna',
  'hcfh',
  'hcfc',
  'hcfw',
  'ethw',
  'liquid',
  'flash',
  'speedDash',
  'timer',
  'warning',
  'info',
  'arrowDown',
  'arrowUp',
  'checked',
  'playMarket',
  'appStore',
  'telegram',
  'discord',
  'youtube',
  'logo-text',
  'logo',
  'marketplace',
  'data-center',
  'rewards',
  'race',
  'liquidation',
  'news',
  'documents',
  'support',
  'filter',
  'dollar',
  'defaultAvatar',
  'close',
  'tronLink',
  'walletConnect',
  'tokenPocket',
  'bitKeep',
  'okx',
  'ledger',
  'video',
  'enigma',
  'liquid-vertical',
  'atom-mining',
  'search',
  'externalLink',
  'burger',
  'wallet',
  'bag',
  'asics_bg',
  'plus_bg',
  'inactive_bg',
  'photo',
  'blockpass',
  'copyDocuments',
  'pen',
  'arrowRightLine',
  'home',
  'usdt',
  'print',
  'refresh',
  'tether',
  'shop',
  'user',
  'KYC',
  'email',
  'passwordKey',
  'pencil',
  'link',
  'price_icon',
  'settings',
  'gem',
  'liquidationArrowDown',
  'X',
  'whatsapp',
  'share',
  'facebook',
  'coin_stats_new',
  'coins',
  'shortContract',
  'calculator',
  'liquidWalletConnect',
  null,
] as const;

export type IconName = (typeof IconsName)[number];

interface IconProps {
  size?: string;
  name: IconName;
  className?: string;
  onClick?: (props: any) => any; // for all cases that it may cause
}

export const Icon: React.FC<IconProps> = ({ name, ...props }) =>
  name ? (
    <svg fill="none" {...props}>
      <use href={`/all_icons.svg#${name}`} />
    </svg>
  ) : null;
