import styled, { css } from 'styled-components';
import { Checkbox } from 'ui';
import { colors, font } from 'theme';
import { Icon } from '../Icon';
import Button from '../Button';
import SmallInput from '../Input/SmallInput.styled';

export const SelectContainer = styled.div`
  position: relative;
  width: fit-content;
`;

export const SelectButton = styled.button<{ $open: boolean; $isSomeItemChoose?: boolean; $isMobileView?: boolean }>`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ $isSomeItemChoose, theme }) =>
    $isSomeItemChoose ? theme.select.hoverTextColor : theme.fontsColor.primary};
  background: ${({ theme }) => theme.select.buttonBackground};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  gap: 0.5rem;
  white-space: nowrap;

  transition: color 0.3s linear;
  ${({ $open }) =>
    $open &&
    css`
      color: ${({ theme }) => theme.select.hoverTextColor};
      transition: color 0.3s linear;
    `}

  ${({ $isMobileView }) =>
    !$isMobileView &&
    css`
      &:hover {
        color: ${({ theme }) => theme.select.hoverTextColor};
        transition: color 0.3s linear;
      }
    `}
`;

interface DropdownContainerProps {
  align?: 'left' | 'right';
  $isOpen: boolean;
}

export const DropdownContainer = styled.div<DropdownContainerProps>`
  position: absolute;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.select.dropdownBackground};
  padding: 1rem 1rem;
  z-index: 5;
  left: ${({ align }) => (align === 'left' ? '0' : 'unset')};
  right: ${({ align }) => (align === 'right' ? '0' : 'unset')};
  top: calc(100% + 1rem);
  width: max-content;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
          transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          transform: translateY(-1rem);
          transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
        `}
`;

export const DropdownInnerContainer = styled.div`
  max-height: 12.5rem;
  overflow: auto;
`;

export const Arrow = styled(Icon)<{ $isOpen: boolean }>`
  width: 1.5rem;
  height: 1.5rem;

  ${({ name, $isOpen }) =>
    name?.includes('arrow') &&
    css`
      transform: ${$isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
      transition: transform 0.3s ease-in-out;
    `}
`;

export const DropdownElement = styled.div<{ $isActive?: boolean }>`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.fontsColor.primary};
  text-align: left;
  cursor: pointer;
  padding: 0 1rem;
  border-radius: 8px;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ $isActive, theme }) => ($isActive ? theme.select.hoverTextColor : theme.fontsColor.primary)};
  &:hover {
    color: ${({ theme }) => theme.select.hoverTextColor};
  }
`;

export const DropdownMultipleElement = styled(DropdownElement)`
  padding: 0 1rem 0 0;
`;

export interface RangeDropdownContainerProps {
  $left?: number;
  $right?: number;
  $isOpen: boolean;
}

export const RangeDropdownContainer = styled(DropdownContainer)<RangeDropdownContainerProps>`
  padding: 1.5rem;
  left: ${({ $left }) => ($left !== undefined ? `${$left}px` : 'unset')};
  right: ${({ $right }) => ($right !== undefined ? `${$right}px` : 'unset')};
  text-align: left;
  width: unset;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
          transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          transform: translateY(-1rem);
          transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
        `}
`;

export const RangeInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`;

export const RangeInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-left: 0.87rem;
  }
`;

export const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.fontsColor.primary};
  font-size: 0.875rem;
  margin-bottom: 0.8rem;
`;

export const RangeInput = styled(SmallInput)<{ $isError: boolean }>`
  width: 100%;
  opacity: 0.8;
  color: ${({ $isError }) => $isError && 'red'};
`;

export const RangeButton = styled(Button)`
  > span {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    font-family: ${font.primary};
    color: ${colors.black};
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  cursor: pointer;
  padding: 0 1rem 0 0;
  border-radius: 8px;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
