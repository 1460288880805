import { GuideLocaleType, NftLocaleType } from 'pages/Landing/types';
import { IMAGE_URL } from 'assets/Urls';

interface IGuideInfo {
  localeValue: GuideLocaleType;
  img?: string;
}

export const guideInfo: Record<string, IGuideInfo> = {
  nfts: { localeValue: 'nfts' },
  tron: {
    localeValue: 'tron',
    img: IMAGE_URL.Landing.Tron,
  },
  exclusive_options: {
    localeValue: 'exclusive_options',
    img: IMAGE_URL.Landing.ExclusiveFeatures,
  },
  liquidation: {
    localeValue: 'liquidation',
    img: IMAGE_URL.Landing.ConstantUpdates,
  },
} as const;

interface INftInfo {
  localeValue: NftLocaleType;
  equipment: string;
  img: string;
}

export const nftInfo: Record<string, INftInfo> = {
  start: {
    localeValue: 'start',
    equipment: 'Liquid START GPU',
    img: IMAGE_URL.Landing.LiquidStartGPU,
  },
  plus: {
    localeValue: 'plus',
    equipment: 'Liquid PLUS Container',
    img: IMAGE_URL.Landing.LiquidPLUS_Container_installed,
  },
  asic: {
    localeValue: 'asic',
    equipment: 'Liquid ASIC',
    img: IMAGE_URL.Landing.LiquidASIC_BTC,
  },
};
